import { StaticImage } from "gatsby-plugin-image";
import React from "react";

export const Partnerships: React.FC = () => {
  return (
    <section className="w-full py-12 bg-gray-50 sm:py-20">
      <div className="flex flex-col items-center justify-center mx-auto xl:flex-row max-w-7xl">
        <div className="relative flex flex-col items-start justify-center w-full h-full max-w-2xl px-6 mx-auto xl:max-w-none xl:w-2/5">
          <h2 className="text-xl font-normal leading-none text-gray-900 sm:w-4/5 sm:text-4xl">
            Study with our partners
          </h2>
          <div className="w-20 h-1 mt-3 ml-1 bg-purple-600 rounded-full"></div>

          <svg
            className="absolute top-0 right-0 hidden w-auto h-24 mt-20 fill-current sm:block xl:mr-5 xl:-mt-24 text-purple-600"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 180 74"
          >
            <defs></defs>
            <g fill-rule="nonzero">
              <path
                d="M43.13 71.58c.29-.025.58-.025.87 0 .3 0 .71-.14 1-.17.54 0 1.08-.19 1.62-.23l2.3-.17 4.65-.25 6.47-.26h.68c3-.15 6-.3 9-.53 1.68-.14 5.83-.49 6.21-.52.63 0 4.36-.53 5-.63.25 0 1.95-.35 3.35-.58-.09-1-.55-1.77-.62-2.71-4.33.66-8.7 1.18-13 1.51-5.34.4-10.71.66-16.08 1l-7 .39c-1.4 0-2.82-.31-4.17-.26a2 2 0 00-1 .31c-.3.21-.4.16-.5.3a1.18 1.18 0 00-.12.81c.06.84.66 2.11 1.34 1.99zM106.33 62.04l.5-.23c.31-.13.72-.33 1.16-.56.88-.46 1.94-1 2.59-1.38l.56-.31a31.87 31.87 0 002.81-1.74 48.77 48.77 0 0014-13.59 38.22 38.22 0 004.34-8.87 28.9 28.9 0 001.51-9.86 31.56 31.56 0 00-3.3-13.46 23.9 23.9 0 00-3.62-5.22 20.47 20.47 0 00-2.38-2.22c-.42-.34-.89-.63-1.33-.94a10.88 10.88 0 00-1.38-.85A18.21 18.21 0 00109.16.92c-.5.11-1 .22-1.45.33-.45.11-.88.31-1.3.47-.42.16-.86.3-1.27.49-.41.19-.8.41-1.21.61A27.71 27.71 0 0098.5 6.5l-1.51 1.42-1.59 1.84c-.51.66-1 1.36-1.44 2-.44.64-.75 1.28-1 1.78a22.66 22.66 0 00-2 5 23.44 23.44 0 00-.82 5.31 26.71 26.71 0 00.72 7c.22 1.16.65 2.26 1 3.38a33.94 33.94 0 001.41 3.21 36.93 36.93 0 008.44 10.95 47.5 47.5 0 005.77 4.43 35.5 35.5 0 0010.02 4.59 86.41 86.41 0 0010 2.09 84.59 84.59 0 0018.2.51c4.8-.31 9.33-.8 13.8-1.39 2.25-.3 4.49-.63 6.76-1l3.43-.59.83-.11a15.59 15.59 0 001.98-.25 46 46 0 014.66-.82c.69-.12 2.24-.87 2.34-1.35.06-.28-.19-.56-.15-.85.09-.65-1.16-1.47-2.06-1.25-.9.22-1.89.51-2.84.73-.95.22-2 .37-3 .62a9.82 9.82 0 00-1.16.38c-.19.06-.39.13-.58.18l-.29.08-.69.12c-3.55.62-7 1.3-10.81 1.68-5.54.53-11.42 1.31-17.15 1.37a73.61 73.61 0 01-18.84-1.81 41.54 41.54 0 01-16.81-8.06 37.32 37.32 0 01-7.9-8.78 27.1 27.1 0 01-4.12-10.8C91.25 17.6 98.76 6.5 108.89 3.76a15.83 15.83 0 0114.56 3.4 23.24 23.24 0 017.36 13.74 28.32 28.32 0 01.29 8 28.05 28.05 0 01-2.06 7.7 37.16 37.16 0 01-5 8.63 39.08 39.08 0 01-7 7l-.87.66-.14.11c-1.69 1.29-3.61 2.56-5.55 3.75a54.34 54.34 0 01-12 5.4c-.42 1-1 2.35-.6 3.17 1.18-.35 2.25-.69 3.52-1.19.8-.28 1.61-.63 2.44-1 .83-.37 1.66-.72 2.49-1.09z"
                className=""
              ></path>
              <path
                d="M46.93 71a8.72 8.72 0 011.16 0c.51 0 1.48.05 2 0l8.52-.5c8.84-.54 17.78-1 26.66-2.45 2.33-.38 4.67-.8 7-1.29a56.65 56.65 0 0010.45-3.26 85.2 85.2 0 009.11-4.57 48.44 48.44 0 0014-12c.86-1 1.57-2.14 2.33-3.2s1.34-2.12 1.89-3.23a35.91 35.91 0 002.81-7.11 31.08 31.08 0 00.4-12.78 6.21 6.21 0 01-1.89 2.64 25.44 25.44 0 01-1 9.32l-.09.26a21.31 21.31 0 01-.69 2 41.94 41.94 0 01-3.72 7.43 41.78 41.78 0 01-5.3 6.63 52.72 52.72 0 01-15.45 10.61 73.71 73.71 0 01-18.17 5.41 207.23 207.23 0 01-24.09 2.59l-15.92.87a4.07 4.07 0 01-.01 2.63zM21.1 71.79a1.43 1.43 0 01-.27-1.49 2.72 2.72 0 011.81-1.54c1-.14 2.13.44 3.2.44 1.47 0 2.94-.27 4.42-.39 1-.08 1.92 0 2.86-.15a17 17 0 012.57-.11 5.7 5.7 0 001.17 0 3 3 0 011.12-.16c1 .18 1.3 2.22.71 2.91-.45.53-1.56.36-2.18.36h-2.67c-2.13.13-4.28 0-6.41.1-.91 0-1.8.24-2.7.35-.9.11-1.7.15-2.56.2a1.31 1.31 0 01-1.07-.52zM7.5 71.7a3.09 3.09 0 010-1 1.26 1.26 0 01.4-.74 2.18 2.18 0 012.16-.49 9.2 9.2 0 002.87 0 9.22 9.22 0 013.1 0 2 2 0 011.17.72c.46.6.61 1.35-.14 1.8a5.18 5.18 0 01-2.91.44c-1.34-.13-2.75.53-4.15.76a2 2 0 01-1.34-.22A2.49 2.49 0 017.5 71.7zM.01 71.57c.082-.29.2-.569.35-.83a1.91 1.91 0 013.27-.25c.54.63.61 2.26-.16 2.72a4.27 4.27 0 01-1.32.44c-1.12.1-2.05-.23-2.14-2.08z"
                className=""
              ></path>
            </g>
          </svg>
        </div>
        <div className="box-content relative flex items-center w-full h-auto max-w-3xl py-5 mx-auto overflow-hidden xl:w-3/5 rounded-xl bg-gradient-to-r from-transparent via-transparent to-gray-100">
          <div className="flex md:flex-row flex-col items-center space-y-6 md:space-y-0 justify-center w-full px-2 md:px-6 md:space-x-6 transition duration-500 ease-out transform xl:pl-6 h-94 relative">
            <PartnershipBlock
              name={"Study Buddies"}
              description={
                <>
                  A friendly community server dedicated to busy students who
                  need a little push now and then to start studying or stay
                  productive throughout the day.
                  <br />
                  <br />
                  Offering daily study sessions, levelling systems, study events
                  and so much more.
                </>
              }
              img={
                <StaticImage
                  className="object-cover w-full h-32 sm:h-48"
                  src={"../../images/partners/studybuddies.png"}
                  alt="Study Buddies Banner"
                />
              }
              link={"https://discord.com/invite/EwWUkeK3tc"}
              caption={"2500+ Members"}
            />
            <PartnershipBlock
              name={"Mythology Multiverse"}
              description={
                <>
                  Online Discord community with a single aim: To promote the
                  interest, intrigue, and study of all things Mythological.
                  <br />
                  <br />
                  They take great pride in our ability to cater to the novices,
                  students, educators, and experts of Mythology alike.
                </>
              }
              img={
                <StaticImage
                  className="object-cover w-full h-32 sm:h-48"
                  src={"../../images/partners/mythological.jpg"}
                  alt="Mythology Multiverse Banner"
                />
              }
              link={"https://discord.gg/dgMn9hKByk"}
              caption={"2300+ Members"}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

const PartnershipBlock: React.FC<{
  name: string;
  description: JSX.Element;
  img: JSX.Element;
  caption: string;
  link: string;
}> = ({ name, description, caption, img, link }) => {
  return (
    <div className="flex flex-col flex-shrink-0  w-4/6 md:w-1/2 overflow-hidden rounded-lg shadow-lg">
      <a href={link} target="_blank">
        {/* <a href="#_"> */}
        <div className="flex-shrink-0">{img}</div>
        <div className="flex flex-col justify-between flex-1 p-6 bg-white">
          <div className="flex-1">
            <p className="inline-block py-1 pl-10 pr-4 mb-2 -ml-10 text-xs font-medium leading-5 text-white transform -translate-y-2 bg-purple-600 rounded">
              Discord
            </p>
            <div className="block">
              <span className="mt-2 text-base font-semibold leading-tight leading-7 text-gray-900 sm:text-xl">
                {name}
              </span>
              <span className="block mt-3 text-xs leading-6 text-gray-500 sm:text-sm">
                {description}
              </span>
            </div>
          </div>
          <div className="items-center hidden mt-6 sm:flex">
            <div className="relative">
              <p className="text-xs font-medium leading-5 text-gray-500">
                {/* <a href="#_" className="hover:underline">
                John Doe
              </a> */}
                {/* <span className="mx-1">·</span> */}
                <time className="ml-1">{caption}</time>
              </p>
            </div>
          </div>
        </div>
        {/* </a> */}
      </a>
    </div>
  );
};
