class SeoText {
  title = "Zorbi | Spaced Repetition Flashcards from Chrome and Notion";
  description =
    "Flashcards that can predict when you'll forget them. Zorbi makes it easy for anyone to create flashcards from Chrome and note-taking tools like Notion.";
  setTitle(text: string) {
    this.title = text;
    return this;
  }
  setDescription(text: string) {
    this.description = text;
    return this;
  }
}

export const index = new SeoText().setTitle(
  "Zorbi | Spaced Repetition Flashcards"
);

export const partnershipsSEO = new SeoText()
  .setTitle("Zorbi | Partnerships")
  .setDescription(
    "Here are the top study communities on Discord. All of our study partners have supportive communities that can help you make friends, connect with mentors, and they even help with homework.."
  );

export const pricingSEO = new SeoText()
  .setTitle("Zorbi | Upgrade To Zorbi Pro")
  .setDescription(
    "Slick upgrades for those that want more efficiency, flexibility, and power. Learn faster through image occlusion, smart learning profiles, text-to-speech, and more."
  );

export const faq = new SeoText()
  .setTitle("Zorbi | FAQ")
  .setDescription(
    "Frequently asked questions about Zorbi - a spaced-repetition flashcard app that makes it easy to create flashcards from Chrome and note-taking tools like Notion."
  );

export const whatIsSpacedRepetition = new SeoText()
  .setTitle("What is Spaced Repetition?")
  .setDescription(
    "Learn about the scientifically proven benefits of spaced repetition and active recall for studying. Discover how Zorbi's user-friendly platform makes it easy to integrate these techniques into your learning routine and improve retention."
  );

export const ankiAlternative = new SeoText()
  .setTitle("Zorbi | Free Anki Alternative")
  .setDescription(
    "Join 50K+ other students that switched from Anki to Zorbi. Zorbi is a free alternative to Anki that makes creating flashcards faster and easier. With Zorbi, you won't have to worry about addons, settings, or updates that might corrupt your data."
  );

export const quizletAlternative = new SeoText()
  .setTitle("Zorbi | Quizlet Alternative with Spaced Repetition")
  .setDescription(
    "Join 50K+ other students that switched from Quizlet to Zorbi. Zorbi makes studying easy by only showing you the flashcards you've forgotten. Quizlet puts a price on efficient learning and basic features like card customization - Zorbi will never do that."
  );

export const teachersPage = new SeoText()
  .setTitle("Zorbi | Spaced Repetition for Teachers and Schools")
  .setDescription(
    "Integrate spaced retrieval into your classroom and improve grades in less than a semester. Zorbi makes spaced repetition easy for students to adopt. "
  );

export const gettingStarted = new SeoText()
  .setTitle("Zorbi | Getting started with Zorbi")
  .setDescription(
    `Zorbi is built on the two most effective learning techniques in the world: active recall and spaced-repetition.\n\nAfter adopting these techniques, I personally saw my learning speed increase by 2-3x and I've seen dozens of people experience the same effect. But the truth is, using them effectively...`
  );

export const makingGoodFlashcards = new SeoText()
  .setTitle("Zorbi | 13 Rules for Making Useful Flashcards")
  .setDescription(
    "Making good flashcards can be daunting but it is super useful once you've mastered it. This guide gives you a list of thirteen simple rules that will help you learn twice as fast through spaced repetition flashcards."
  );

export const chromeFlashcards = new SeoText()
  .setTitle("Zorbi | Making Flashcards in Chrome")
  .setDescription(
    "Use the Zorbi Chrome Extension to quick-capture flashcards from any website or PDF. Zorbi makes memorization easy through spaced repetition flashcards that can predict when you'll forget them. "
  );

export const notionFlashcards = new SeoText()
  .setTitle("Zorbi | Making Flashcards in Notion")
  .setDescription(
    "Zorbi makes it easy to create and sync spaced-repetition flashcards from Notion notes into Zorbi. Zorbi works on every device, it's free, and it's backed by science. "
  );
