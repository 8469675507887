import React from "react";
import SEO from "../components/common/layout/seo";
import Layout from "../components/common/layout/layout";
import { Navigation } from "../components/common/navigation/navigation";
import { Partnerships } from "../components/sections/partnerships";
import { partnershipsSEO } from "../utils/seoText";
import { Footer } from "../components/sections/footer";

export const PartnershipsPage = () => {
  return (
    <Layout>
      <SEO {...partnershipsSEO} />
      <Navigation />
      <Partnerships />
      <Footer />
    </Layout>
  );
};

export default PartnershipsPage;
